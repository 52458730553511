import React from "react";
import {InputTime, InputType} from "../../components/Form/Input/Index";
import {faFolderOpen, faHourglassHalf, faFile, faClipboardList} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../../components/Form/Dropdown/Index";
import {validateOBJECT, validateTIME} from "../../helpers/data-validation";
import Editor from "../../components/Form/Editor/Index";
import {animErrorForm} from "../../helpers/animation";
import EventBus from "eventbusjs";
import {getReportingRawData} from "../../providers/provider";


export default class ReportForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            project: null,
            devis: null,
            content: "",
            timeSpent: "01:00",
            ticketNo: "",

            projectsList: null,
            devisProjectList: null,
            clientsList: null,
            teamsList: null,

            devisData: null
        };

        this.getPageData = this.getPageData.bind(this);
    }


    componentDidMount() {
        this.getPageData();
        EventBus.addEventListener('raw-data-event', this.getPageData);
    }


    componentWillUnmount() {
        EventBus.removeEventListener('raw-data-event', this.getPageData);
    }


    getPageData = function(){

        const projects =  getReportingRawData("projects");
        const devis =  getReportingRawData("devis");
        const clientsList = getReportingRawData("clients")
        const teamsList = getReportingRawData("teams")

        if(projects !== null && devis !== null){

            let projectsList = {};
            projectsList.metadata = projects.filter((project) => project.enabled).sort((a, b) => a.name.localeCompare(b.name))
            projectsList.displayData = projectsList.metadata.map((project) => {return project.name});

            this.setState({projectsList, devisData: devis, clientsList, teamsList})
        }
    };


    loadProjectDevis(project_metadata){

        let project_identifier = project_metadata['@id'];
        let devisProjectList = {};

        devisProjectList.metadata = this.state.devisData.filter((devis) => devis.project === project_identifier && devis.enabled).sort((a, b) => {
            if (a.tag > b.tag)
                return 1;
            if (b.tag > a.tag)
                return -1;
            return 0;
        });

        devisProjectList.displayData = [];
        devisProjectList.metadata.forEach((devis) => devisProjectList.displayData.push(devis.tag));
        this.setState({devisProjectList});
    }


    addReport(){
        if(validateOBJECT(this.state.project, this.state.projectsList) && validateOBJECT(this.state.devis, this.state.devisProjectList) && validateTIME(this.state.timeSpent)){
            let newReport = {
                project: this.state.project,
                devis: this.state.devis,
                content: this.state.content,
                timeSpent: this.state.timeSpent,
                ticketNo: this.state.ticketNo != '' ? this.state.ticketNo : null
            };
            this.props.addReport(newReport);
            this.resetForm();
        }
        else{
            console.error("form error");
            animErrorForm();
        }
    }


    resetForm(){
        this.setState({
            project: null,
            devis: null,
            content: "",
            timeSpent: "",
            ticketNo: ""
        });
    }


    render() {

        return (
            <div className={"form content"} style={(this.props.display)? {display: "flex"} : {display: "none"}}>
                <div className={"container"}>

                    <Dropdown label={"Project"} icon={faFolderOpen}
                              value={this.state.project} data={this.state.projectsList}
                              onChange={(project)=>{
                                  this.setState({project, devis: null});
                                  if(project !== null) this.loadProjectDevis(project.metadata)
                              }}/>

                    <Dropdown label={"Devis"} icon={faFile} disabled={(this.state.devisProjectList === null || this.state.devisProjectList.metadata.length === 0)}
                              value={this.state.devis} data={this.state.devisProjectList}
                              onChange={(devis)=>{this.setState({devis})}}/>

                    <div className={"complement"} style={(this.state.project === null || this.state.project === "")? {visibility: "hidden", height: 0} : undefined}>
                        <p>
                            <span>Client : </span>
                            {(this.state.project !== null && this.state.project !== "")
                            && this.state.clientsList.find((client) => client['@id'] === this.state.project.metadata.client).name}
                        </p>
                        <p className={"team"}>
                            <span>Team : </span>
                            {(this.state.devis !== null && this.state.devis !== "")
                            && this.state.teamsList.find((team) => team['@id'] === this.state.devis.metadata.team).name}
                        </p>
                    </div>

                    <InputTime label={"Time spent (hh:mm)"} icon={faHourglassHalf}
                               value={this.state.timeSpent}
                               onChange={(time)=>{this.setState({timeSpent: time})}}/>

                    <InputType
                        label={"Ticket No"}
                        icon={faClipboardList}
                        value={this.state.ticketNo}
                        type={"text"}
                        onChange={(event) => {this.setState({ticketNo: event.target.value})}}
                    />

                    <Editor title={"Description"} content={this.state.content} onChange={(content) => this.setState({content})}/>

                </div>

                <div className={"control-container"}>
                    <button id={"add-trigger"} className={"important shake-constant"} onClick={() => this.addReport()}>ADD</button>
                </div>
            </div>
        );
    }
}
