import EventBus from 'eventbusjs';
import getApiRawData from "./api-data-provider";
import {isEmpty} from "../helpers/obj-helper";


const reportingData = {
    reportsList: undefined,
};


const rawApiData = {};


export default function startReportingDataLoading(){

    //initMercureConnection();

    getApiRawData().then((values) => {

        values.forEach((value) => rawApiData[value[0]] = value[1]);
        EventBus.dispatch("raw-data-event")
        generateReportingData();
    });
}


export function getReportingRawData(data_type){
    if(typeof data_type === "undefined") return rawApiData;
    else if(isEmpty(rawApiData[data_type]) || rawApiData[data_type] === null || typeof rawApiData[data_type] === "undefined") return null;
    else return rawApiData[data_type];
}


export function updateReportingRawData(data, type){
    if(type === "reports") [].push.apply(rawApiData.reports, data)
    else Object.assign(rawApiData, data);
    generateReportingData();
}


export function getReportingData(data_type){
    if(typeof data_type === "undefined") return reportingData;
    else if(typeof reportingData[data_type] === "undefined") return "loading";
    else if(isEmpty(reportingData[data_type]) || reportingData[data_type] === null) return [];
    else return reportingData[data_type];
}


export function generateReportingData(){
    let devis_project = [];
    for(let d=0; d < rawApiData.devis.length; d++){
        let devis = Object.assign({},  rawApiData.devis[d]);
        devis.project = rawApiData.projects.find((project) => project['@id'] === devis.project);
        devis_project.push(devis);
    }

    let reportsList = [];
    for(let r=0; r < rawApiData.reports.length; r++){
        let report = Object.assign({}, rawApiData.reports[r]);
        report.employee = rawApiData.employees.find((employee) => employee['@id'] === report.employee);
        report.devis = devis_project.find((devis) => devis['@id'] === report.devis);
        reportsList.push(report);
    }

    reportingData.reportsList = reportsList.sort((a, b) => {
        if(a.creationDate > b.creationDate) return -1;
        if(b.creationDate > a.creationDate) return 1;
        return 0
    });
    EventBus.dispatch("reports-list-data-event");
}


