import {minutesToClock} from "../../helpers/format-helper";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEye} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "./style.scss";


const UserRow = (props) => {

    return (
        props.report.devis.project ?
        <tr key={props.index} className={(props.TSselected)? "user-report ts-selected" : "user-report"}>

            <td className={"index-user"}>{props.report.id}</td>

            <td className={"user-user"}>{props.report.employee.firstName + " " + props.report.employee.lastName}</td>

            <td className={"project"}>
                {props.report.devis.project.name}
            </td>

            <td className={"devis"}>
                {props.report.devis.tag}
            </td>

            <td className={"ts"}>{minutesToClock(props.report.minutesPerDay, 'h')}</td>

            <td>{moment.utc(props.report.creationDate).format("MMM DD, HH:mm")}</td>

            {(props.report.isSent) ?
                <td className={"status sent"}>SENT</td>

                :
                <td className={"status"}>PENDING</td>
            }

            <td className={"actions"}>
                <button onClick={() => props.displayReport(props.report)} style={{marginRight: 5}}>
                    <FontAwesomeIcon icon={faEye} className={"icon"}/>
                    Show
                </button>

                {!props.report.isSent &&
                <button onClick={() => props.startEditReport(props.report)}>
                    <FontAwesomeIcon icon={faEdit} className={"icon"}/>
                    Edit
                </button>
                }
            </td>
        </tr> : null
    )
}


export default UserRow
