import React from "react";
import "./style.scss";
import {Button, Modal} from "react-bootstrap";


export default class ReportModal extends React.Component {

    render() {

        let report = this.props.report;

        if(typeof report !== "undefined" && report !== null){

            return (
                <Modal size="lg" centered show={this.props.show} onHide={this.props.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{"[" + report.project.displayData + "]"}</Modal.Title>
                        <h5>{"Devis: " + report.devis.displayData}</h5>
                        <span>Time spent : {report.timeSpent} h</span>
                    </Modal.Header>

                    {/* RISQUE DE FAILLE XSS A CORRIGER */}
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{__html: report.content}}/>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        else return null;
    }
}