import React from "react";
import "./style.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCog, faPlus, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {logout} from "../../../helpers/security";
import Cookies from "js-cookie";
import {Base64} from "js-base64"


export default class Settings extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isSettingsMenuOpen: false,
            user_roles: JSON.parse(Base64.decode(Cookies.get("sso-reporting-data"))).roles
        };
        this.toggleSettingsMenu = this.toggleSettingsMenu.bind(this);
    }


    componentDidMount() {
        window.addEventListener('click', (e)=> this.triggerClickOutside(e));
    }


    componentWillUnmount() {
        window.removeEventListener('click', (e)=> this.triggerClickOutside(e));
    }


    triggerClickOutside(event){
        if (!document.getElementsByClassName('settings-container')[0].contains(event.target)){
            this.toggleSettingsMenu(false);
        }
    }


    toggleSettingsMenu(open){
        this.setState({isSettingsMenuOpen: open});
    }


    render(){

        return(
                <div className={"settings-container"}>
                    <FontAwesomeIcon icon={faCog} className={(this.state.isSettingsMenuOpen)? "settings-trigger open" :  "settings-trigger"}
                                     onClick={()=>this.toggleSettingsMenu(!this.state.isSettingsMenuOpen)}/>

                    <div className={(this.state.isSettingsMenuOpen)? "settings-menu open" : "settings-menu"}>

                        {!this.state.user_roles.includes("ROLE_ADMIN") &&

                            <NavLink exact={true} activeClassName='active' to={"/add-report"} className={"add-timesheet settings-item"}
                                     onClick={()=>this.toggleSettingsMenu(!this.state.isSettingsMenuOpen)}>
                                <FontAwesomeIcon className={"icon"} icon={faPlus}/>
                                <p>ADD REPORT</p>
                            </NavLink>
                        }

                        {this.state.user_roles.includes("ROLE_MANAGER") &&

                            <NavLink exact={true} activeClassName='active' to={"/validation"} className={"settings-item"}
                                    onClick={()=>this.toggleSettingsMenu(!this.state.isSettingsMenuOpen)}>
                                <FontAwesomeIcon className={"icon"} icon={faCheck}/>
                                <p>VALIDATION</p>
                            </NavLink>
                        }

                        <div className={"logout settings-item"} onClick={() => {
                            this.toggleSettingsMenu(!this.state.isSettingsMenuOpen);
                            logout();
                        }}>
                            <FontAwesomeIcon className={"icon"} icon={faSignOutAlt}/>
                            <p>LOGOUT</p>
                        </div>

                    </div>
                </div>
        );
    }
}
