import React, {useEffect, useState} from "react"
import classes from "./style.module.scss"
import {getReportingRawData} from "../../providers/provider";
import EventBus from "eventbusjs";
import {Base64} from "js-base64";
import Cookies from "js-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import moment from "moment"
import {minutesToClock} from "../../helpers/format-helper";
import Loading from "../../components/Loading/Index";
import {query_api_put} from "../../helpers/ajax";
import loader from "../../res/icons/loader-black.svg";


export default function Validation(props) {

    const [data, setData] = useState(null)
    const user_id = JSON.parse(Base64.decode(Cookies.get("sso-reporting-data"))).api_id
    const timesheets = []
    let project = {}

    if(data !== null)
        data.reports.filter((report) => report.validated === undefined && report.isSent).forEach((report, index) => {
            const devis = data.devis.find((devis) => devis['@id'] === report.devis)
            const project = data.projects.find((project) => project['@id'] === devis.project)
            const team = data.teams.find((team) => team['@id'] === devis.team)

            if(team.responsable === "/api/employees/" + user_id) {
                const week = moment(report.creationDate.split("T")[0]).startOf('day').isoWeek()
                const timesheet = timesheets.find((timesheet) => timesheet.devis === devis && timesheet.week === week)

                if(timesheet !== undefined) {
                    timesheet.reports.push(report)

                } else {
                    if (project) {
                        timesheets.push({
                            name: project.name + " | " + devis.tag,
                            team: team,
                            week: week,
                            devis: devis,
                            project: project,
                            reports: [report]
                        })
                    }
                }
                timesheets.sort((a, b) => {
                    if(a.name === b.name) return a.week - b.week
                    else return a.name.localeCompare(b.name)
                })
            }
        })


    useEffect(() => {
        if (getReportingRawData().reports !== undefined) loadData()
        else {
            EventBus.addEventListener('raw-data-event', () => loadData());
            return () => EventBus.removeEventListener('raw-data-event', () => loadData());
        }
    }, [])


    const loadData = () => {
        setData(getReportingRawData())
    }


    return(
        <div className={"page"}>
            <div className={classes.container}>
                <h2>VALIDATION</h2>
                <p id={"advice"}>Validation of timesheets</p>

                <div className={classes.content}>
                    {(data !== null)?
                        timesheets.map((timesheet, index) => <Timesheet key={index} timesheet={timesheet} data={data}/>)
                        : <Loading message={"Loading timesheets ..."}/>
                    }
                </div>
            </div>
        </div>
    )
}


const Timesheet = (props) => {

    const { timesheet, data } = props
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)
    const startDate = moment().isoWeek(timesheet.week).isoWeekday(1)
    const endDate = moment().isoWeek(timesheet.week).isoWeekday(5)
    const employees = data.team_compositions.filter((composition) => composition.team === timesheet.team['@id'])
        .map((composition) => data.employees.find((employee) => employee['@id'] === composition.employee))
            .sort((a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName))


    const handleClick = () => {
        if(!done) setOpen(!open)
    }


    const handleValidation = (validated) => {

        const put_promises = [];
        setLoading(true)

        timesheet.reports.forEach((report) => {
            report.validated = validated
            put_promises.push(query_api_put("/api/reports/" + report.id, report))
        })

        Promise.all(put_promises)
            .then(() => {
                setOpen(false)
                setLoading(false)
                setTimeout(() => setDone(true), 300)
            })
            .catch((error) => {
                console.error(error)
                setLoading(false)
            })
    }


    return (
        <div className={(done)? classes.team + " " + classes.validated : classes.team}
             style={{borderLeft: "15px solid " + timesheet.team.color}}>

            <div className={classes.header} onClick={handleClick}>
                <h2>{timesheet.name}<span>{startDate.format("DD/MM/YYYY")} - {endDate.format("DD/MM/YYYY")}</span></h2>
                {!done && <FontAwesomeIcon className={(open)? classes.open : undefined} icon={faChevronLeft}/>}
            </div>

            <div className={(open)? classes.panel + " " + classes.open : classes.panel}>
                <div className={classes.table}>
                    <HeaderRow/>
                    {employees.map((employee, index) =>
                        <ReportRow key={index} employee={employee} reports={timesheet.reports}/>
                    )}
                </div>

                <div className={classes.control}>
                    {loading?
                        <img src={loader} alt={"loading"}/>

                        :

                        <>
                            <button className={classes.important} onClick={() => handleValidation(true)}>{(loading)? <img src={loader} alt={"loading"}/> : "VALIDATE"}</button>
                            <button onClick={() => handleValidation(false)}>{(loading)? <img src={loader} alt={"loading"}/> : "REFUSE"}</button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}


const HeaderRow = () => {
    return (
        <div className={classes.titles}>
            <div className={classes.employee}>Employee full name</div>
            <div className={classes.day}>Mon</div>
            <div className={classes.day}>Tue</div>
            <div className={classes.day}>Wed</div>
            <div className={classes.day}>Thu</div>
            <div className={classes.day}>Fri</div>
            <div className={classes.total}>TOTAL</div>
        </div>
    )
}



const ReportRow = (props) => {

    const { employee, reports } = props
    const employee_reports = reports.filter((report) => report.employee === employee['@id'])
    const hoursPerDay = [0, 0, 0, 0, 0]

    for(let day = 1; day <= 5; day++){
        const reportsPerDay = employee_reports.filter((report) => moment(report.creationDate.split("T")[0]).startOf('day').isoWeekday() === day)
        reportsPerDay.forEach((report) => hoursPerDay[day - 1] += report.minutesPerDay)
    }

    const total = hoursPerDay.reduce((acc, val) =>  acc + val)


    return (
        <div className={classes.line}>
            <div className={classes.employee}>{employee.firstName + " " + employee.lastName}</div>
            {hoursPerDay.map((minutes, index) =>
                <div key={index} className={(minutes !== 0)? classes.report : classes.report + " " + classes.zero}>
                    {minutes !== 0 && minutesToClock(minutes, 'h')}
                </div>
            )}
            <div className={(total !== 0)? classes.total : classes.total + " " + classes.zero}>
                {total !== 0 && minutesToClock(total, 'h')}
            </div>
        </div>
    )
}
