import React from "react";
import "./style.scss";
import CKEditor from '@ckeditor/ckeditor5-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenSquare} from "@fortawesome/free-solid-svg-icons";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import {editorConfiguration} from "../../../conf/editor-conf";

export default class Editor extends React.Component  {

    constructor(props){
        super(props);

        this.state = {
            isFocus: false
        }
    }

    render() {
        return (
            <div className={"editor"}>
                <h4>
                    <FontAwesomeIcon icon={faPenSquare} className={"icon"}/>
                    <span className={(this.state.isFocus)? "focus" : null}>{this.props.title}:</span>
                </h4>
                <CKEditor
                    editor={ ClassicEditor }
                    config={ editorConfiguration }
                    onInit={ editor => {
                        editor.setData(this.props.content)
                    }}
                    onChange={ ( event, editor ) => {
                        this.props.onChange(editor.getData())
                    }}
                    onBlur={ ( event, editor ) => {}}
                    onFocus={ ( event, editor ) => {
                        this.setState({isFocus: true})
                    }}
                />
            </div>
        );
    }
}