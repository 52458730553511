import {minutesToClock} from "../../helpers/format-helper";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "./style.scss"


const ManagerRow = (props) => {

    return (
        props.report.devis && props.report.devis.project ?
            <tr key={props.index} className={"manager-report"}>

                <td className={"index-manager"}>{props.report.id}</td>

                <td>{props.report.employee.firstName + " " + props.report.employee.lastName}</td>

                <td className={"project"}>
                    {props.report.devis.project.name}
                </td>

                <td className={"devis"}>
                    {props.report.devis.tag}
                </td>

                <td>{minutesToClock(props.report.minutesPerDay, 'h')}</td>

                <td>{moment.utc(props.report.creationDate).format("MMM DD, HH:mm")}</td>

                {(props.report.isSent) ?
                    <td className={"status received"}>RECEIVED</td>
                    :
                    <td className={"status"}>PENDING</td>
                }

                <td className={"actions"}>
                    <button onClick={() => props.displayReport(props.report)}>
                        <FontAwesomeIcon icon={faEye} className={"icon"}/>
                        Show
                    </button>
                </td>
            </tr>
        : null
    )
}


export default ManagerRow