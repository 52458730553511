import React from "react";
import "./style.scss";
import {Button, Modal} from "react-bootstrap";
import Editor from "../Form/Editor/Index";
import moment from "moment";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"


const ReportModal = (props) => {

    const report = props.report;

    if(typeof report !== "undefined" && report !== null){
        let content = report.content;

        return (
            <Modal size="lg" centered show={props.show} onHide={() => props.handleClose(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>{"#" + report.id + " " + report.employee.firstName + " " + report.employee.lastName + " ["} <span>{report.devis.project.name}</span> {"]"}</Modal.Title>
                    <h5>{"Devis: " + report.devis.tag}</h5>
                    {report.ticketNo ? <span className={"span-important"}>{"Ticket No: " + report.ticketNo}</span> : null}
                    <span>{moment.utc(report.creationDate).format("LLLL")}</span>
                </Modal.Header>

                {/* RISQUE DE FAILLE XSS A CORRIGER */}
                <Modal.Body>
                    {(props.readOnly)?
                        <div dangerouslySetInnerHTML={{__html: content}}/>
                        :
                        <div className={"editor-container"}>
                            <Editor title={"Edit"} content={content}
                                       onChange={(_content) => {content = _content}}/>
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    {(props.readOnly)?
                        <div className={"right-line"}>
                            <Button variant="secondary" onClick={() => props.handleClose(null)} style={{marginLeft: '10px'}}>
                                Close
                            </Button>
                        </div>

                        :

                        <>
                            <Button variant="primary" onClick={() => props.handleDelete(report)}>
                                <FontAwesomeIcon icon={faTrash}/>
                                Delete
                            </Button>

                            <div>
                                <Button variant="primary" onClick={() => {
                                    const _report = {...report};
                                    _report.content = content;
                                    props.handleClose(_report)
                                }}>
                                    <FontAwesomeIcon icon={faSave}/>
                                    Save
                                </Button>

                                <Button variant="secondary" onClick={() => props.handleClose(null)} style={{marginLeft: '10px'}}>
                                    Close
                                </Button>
                            </div>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        );
    }

    else return null;
}


export default ReportModal