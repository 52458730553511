import React from 'react';
import './style.scss';
import {Navbar, Nav} from "react-bootstrap";
import logo from "../../res/images/esokia_logo.svg"
import {Link} from "react-router-dom";
import menuOn from "../../res/icons/menu-on.svg";
import menu from "../../res/icons/menu.svg";
import Navitem from "./Navitem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Settings from "./Settings/Index";
import {url} from "../../conf/env";


export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
            firstName: this.props.userFirstName.toUpperCase(),
            lastName: this.props.userLastName.toUpperCase()
        };
        this.handleTriggerMenuClick = this.handleTriggerMenuClick.bind(this);
        this.closeCollapseMenu = this.closeCollapseMenu.bind(this);
    }


    handleTriggerMenuClick() {
        if (this.state.isExpanded) this.closeCollapseMenu();
        else{
            document.getElementsByClassName("navbar-toggler-icon")[0].style.background = "url('" + menuOn + "') no-repeat";
            this.setState({isExpanded: true});
        }
    }


    closeCollapseMenu(){
        if (this.state.isExpanded){
            document.getElementsByClassName("navbar-toggler-icon")[0].style.background = "url('" + menu + "') no-repeat";
            this.setState({isExpanded: false});
        }
    }


    render() {

        let username = this.state.firstName + " " + this.state.lastName;
        let userpicture = this.state.firstName.charAt(0) + this.state.lastName.charAt(0);

        return (
            <Navbar className={"nav"} expand={"lg"} expanded={this.state.isExpanded}>
                <Navbar.Brand className={"brand"}>
                    <Link to={"/"}>
                        <img className={"logo"} src={logo} alt={"Esokia logo"}/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle id={"menu-trigger"} onClick={this.handleTriggerMenuClick}/>
                <Navbar.Collapse id="basic-navbar-nav" bg="dark">
                    <Nav className="menu">
                        <Navitem link={"/reports"} title={"REPORTS LIST"} onClick={this.closeCollapseMenu}/>
                        <hr/>
                        <Navitem link={url.sso} out={true} title={"HUB"} onClick={this.closeCollapseMenu}/>
                        <Navitem link={url.forecast} out={true} title={"FORECAST"} onClick={this.closeCollapseMenu}/>
                    </Nav>

                    <Link to={"/add-report"}>
                        <div className={"add-timesheet _collapse"} onClick={this.closeCollapseMenu}>
                            <FontAwesomeIcon className={"icon"} icon={faPlus}/>
                            <p>ADD REPORT</p>
                        </div>
                    </Link>

                    <a href={url.sso} className={"about-user _collapse"} onClick={this.closeCollapseMenu}>
                        <div className={"user-image"}>{userpicture}</div>
                        <h6>{username}</h6>
                    </a>

                </Navbar.Collapse>

                <a href={url.sso} className={"about-user"}>
                    <div className={"user-image"}>{userpicture}</div>
                    <h6>{username}</h6>
                </a>

                <Settings/>
            </Navbar>
        );
    }
}