import React, {useState} from "react";
import "./style.scss";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Dropdown = (props) => {

    const [filter, setFilter] = useState("")
    let value = (props.disabled || props.value === null)? "" :  props.value.displayData
    if(filter !== "") value = filter


    const applyDropDownFilter = (filter) => {
        setFilter(filter)
    }


    const handleDropDownItemClick = (data) => {
        setFilter("")
        props.onChange(data);
    }


    const resetFilter = () => {
        setFilter("")
        props.onChange(null);
    }


    return (
        <div className={"input"} style={props.style}>
            <FontAwesomeIcon className={"icon"} icon={props.icon}/>
            <div className="group">

                <input type={"text"} value={value} required disabled={props.disabled}
                       onChange={(e) => applyDropDownFilter(e.target.value)}
                       onClick={resetFilter}
                />

                <span className="highlight"/>
                <span className="bar"/>
                {(props.disabled)? <label>{"("}<FontAwesomeIcon icon={faLock} className={"label-icon"}/>{") " + props.label}</label> : <label>{props.label}</label>}

                <ul className={"dropdown"}>
                    {
                        (props.data !== null)?
                            props.data.displayData
                                .map((data, index) =>

                                    //On filtre grâce à display none en css pour permettre de toujour utiliser l'index du projet et pouvoir ainsi correctement identifier celui qui est seléctionné.
                                    <li key={index} style={(data.toLowerCase().includes(filter.toLowerCase()))? {display: "block"} : {display: "none"}}
                                        onMouseDown={() => handleDropDownItemClick({displayData: data, metadata: props.data.metadata[index]})}>
                                        {data}
                                    </li>
                                )

                            :

                            <li>Loading data..</li>
                    }
                </ul>
            </div>
        </div>
    )
}


export default Dropdown