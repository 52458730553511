import React from 'react';
import Reporting from "../pages/Reporting/Index";
import ReportList from "../pages/ReportList/Index";
import AddReport from "../pages/AddReport/Index";
import Validation from "../pages/Validation/Index";


export const routes = [
    {
        path: "/",
        exact: true,
        comp: () => <Reporting/>
    },
    {
        path: "/reports",
        exact: true,
        comp: () => <ReportList/>
    },
    {
        path: "/add-report",
        exact: true,
        comp: () => <AddReport/>
    }
];


export const manager_routes = [
    {
        path: "/",
        exact: true,
        comp: () => <Reporting/>
    },
    {
        path: "/reports",
        exact: true,
        comp: () => <ReportList/>
    },
    {
        path: "/add-report",
        exact: true,
        comp: () => <AddReport/>
    },
    {
        path: "/validation",
        exact: true,
        comp: () => <Validation/>
    }
];


export const admin_routes = [
    {
        path: "/",
        exact: true,
        comp: () => <Reporting/>
    },
    {
        path: "/reports",
        exact: true,
        comp: () => <ReportList/>
    }
];