import React from "react";
import "./style.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignInAlt, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";


export default class Reporting extends React.Component {

    render() {
        return (
            <div className={"page"}>
                <div id={"message-container"}>
                    <h1><
                        FontAwesomeIcon icon={faPaperPlane} className={"icon"}/>
                        REPORTING
                    </h1>
                    <p className={"description"}>The term "Reporting" means a business intelligence tools family destined to ensure the realisation, the publication and the diffusion of some activity report built with a predefined format. They are essentially destined to make easier the communication of quantified results and progress monitoring.</p>
                    <p className={"use"}>This tools has been build to save the daily reports for each collaborator. Thank you!</p>
                    <p className={"important"}>Reports are sent daily at 00:00. You have until midnight to edit your report.</p>

                    <Link to={"/reports"}>
                        <button className={"report-list-trigger"}>
                            <FontAwesomeIcon icon={faSignInAlt} className={"icon"}/>
                            REPORTS LIST
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}