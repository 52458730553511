import React from "react";
import "./style.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TimeField from 'react-simple-timefield';


export class InputType extends React.Component {

    render() {
            return (
                <div className={"input"} style={this.props.style}>
                    <FontAwesomeIcon className={"icon"} icon={this.props.icon}/>
                    <div className="group">
                        <input type={this.props.type}
                               value={this.props.value} onChange={this.props.onChange} required/>
                        <span className="highlight"/>
                        <span className="bar"/>
                        <label>{this.props.label}</label>
                    </div>
                </div>
            );
    }
}



export class InputTime extends React.Component {

    render() {
        return (
            <div className={"input time"} style={this.props.style}>
                <FontAwesomeIcon className={"icon"} icon={this.props.icon}/>
                <div className="group">
                    <TimeField
                        className={"time-field"}
                        value={this.props.value}
                        onChange={(event, value) => this.props.onChange(value)}
                        input={
                            <input value={this.props.value} type={"text"} required/>
                        }
                        colon=":"
                    />
                    <span className="highlight"/>
                    <span className="bar"/>
                    <label>{this.props.label}</label>
                </div>
            </div>
        );
    }
}