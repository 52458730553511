import React from "react";
import {NavLink} from "react-router-dom";

export default class NavItem extends React.Component {

    render() {
        if(typeof this.props.out !== "undefined" || this.props.out)
            return (
                <a href={this.props.link} className={"navitem out"}>
                    {this.props.title}
                </a>
            );
        else
            return (
                <NavLink exact={true} activeClassName='active' to={this.props.link} className={"navitem"} onClick={this.props.onClick}>
                    {this.props.title}
                </NavLink>
            );
    }
}