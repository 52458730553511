export function minutesToClock(minutesSpent, delimiter) {

    let hours = Math.floor(minutesSpent / 60);
    let minutes = minutesSpent - hours * 60;

    if(minutes.toString().length === 1) minutes = "0" + minutes;

    return hours + delimiter + minutes;
}


export function clockToMinutes(clock, delimiter) {

    let hoursMinutes = clock.split(delimiter);
    return hoursMinutes[0] * 60 + parseInt(hoursMinutes[1]);
}