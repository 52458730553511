import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import Link from "@ckeditor/ckeditor5-link/src/link";
import Image from "@ckeditor/ckeditor5-image/src/image";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import List from "@ckeditor/ckeditor5-list/src/list";
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';



export const editorConfiguration = {
    plugins: [Essentials, Indent, IndentBlock, List, Strikethrough, Paragraph, TodoList, Image, ImageUpload, ImageResize, Base64UploadAdapter, Autoformat, Heading, Bold, Italic, Link, MediaEmbed, Table, TableToolbar, Alignment, Font, Highlight, Underline, Code ],
    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'strikethrough', 'code', 'fontSize', 'fontColor', 'highlight', '|', 'alignment', 'outdent', 'indent', '|', 'undo', 'redo', '|', 'bulletedList', 'numberedList', 'todolist', 'insertTable','|', 'link', 'imageUpload', 'MediaEmbed'],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
        ]
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: 'http://localhost:2000/res/reports',

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            'X-CSRF-TOKEN': 'CSFR-Token',
            Authorization: 'Bearer <JSON Web Token>'
        }
    },
    placeholder: 'Type your report here!',

    mediaEmbed: {
        previewsInData: true
    }
};