import React, {useState, useEffect} from "react"
import "./style.scss"
import "./paginate.scss"
import Table from "react-bootstrap/Table"
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons"
import ReportModal from "../../components/Modal/ReportModal"
import EventBus from 'eventbusjs'
import Loading from "../../components/Loading/Index"
import moment from "moment"
import Cookies from "js-cookie"
import {query_api_put, query_api_delete} from "../../helpers/ajax"
import {getReportingData, getReportingRawData, updateReportingRawData} from "../../providers/provider"
import {Base64} from "js-base64"
import ReactPaginate from 'react-paginate'
import UserRow from "./UserRow";
import ManagerRow from "./ManagerRow";


const maxLineByPage = 30


const ReportList = () => {

    const [reports, setReports] = useState(null)
    const [filter, setFilter] = useState("")
    const [showModal, setModal] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const [modalReadOnly, setModalReadOnly] = useState(true)
    const loggedUserData = JSON.parse(Base64.decode(Cookies.get("sso-reporting-data")))
    const filtered_reports = (reports === null)? null : reports.filter((report) =>
        (report.devis && report.devis.project && report.devis.project.name.toLowerCase().includes(filter)) ||
        report.devis && report.devis.tag.toLowerCase().includes(filter) ||
        (report.employee.firstName + " " + report.employee.lastName).toLowerCase().includes(filter) ||
        report.id.toString().includes(filter) ||
        moment.utc(report.creationDate).format("MMM DD, HH:mm").toLowerCase().includes(filter)
    )

    const [tableNode, setTableNode] = useState(null)
    const [pageId, setPageId] = useState(0)
    const sliced_reports = (filtered_reports === null)? null : filtered_reports.slice(pageId * maxLineByPage, (pageId + 1) * maxLineByPage)
    const pageCount = (filtered_reports !== null)? Math.ceil(filtered_reports.length / maxLineByPage) : 1
    const [isTSselected, setTSselected] = useState(false)


    useEffect(() => {
        const timeout = setTimeout(loadTableNode, 3000)
        getPageData()
        EventBus.addEventListener('reports-list-data-event', getPageData)
        return (() => {
            EventBus.removeEventListener('reports-list-data-event', getPageData)
            clearTimeout(timeout)
        })
    }, [])


    const loadTableNode = () => {
        const node = document.getElementsByClassName("table-responsive")[0]
        if(typeof node !== "undefined") setTableNode(node)
        else setTimeout(() => setTableNode(document.getElementsByClassName("table-responsive")[0]), 5000)
    }


    const getPageData = () => {
        const response = getReportingData("reportsList")
        setReports((response === "loading")? null : getReportingData("reportsList"))
    };


    const handleCloseEditModal = (report) => {
        if(typeof report === "undefined" || report === null) setModal(false)
        else updateReport(report)
    }


    const handleDeleteFromModal = (report) => {
        if(window.confirm("Are you sure to delete this report ?"))
            query_api_delete(report['@id'])
                .then((res) => {
                    const rawData = {...getReportingRawData()}
                    rawData.reports.splice(rawData.reports.findIndex((r) => r.id === report.id), 1)
                    setModal(false)
                    updateReportingRawData(rawData)
                })
                .catch((err)=> {
                    console.error(err)
                    setModal(false)
                })
    }


    const updateReport = (report) => {
        const _reports = [...reports];
        const editedReportIndex = _reports.findIndex((_report) => _report.id === report.id);
        _reports[editedReportIndex] = report;

        query_api_put("/api/reports/" + report.id, {
            content: report.content,
            minutesPerDay: report.minutesPerDay,
            employee: report.employee['@id'],
            devis: report.devis['@id'],
            isSent: report.isSent,
            creationDate: report.creationDate
        })
            .then((response) => {
                setModal(false)
                setReports(_reports)
            })
            .catch((err) => {
                console.error(err)
            });
    }


    const displayReport = (report) => {
        setModal(true)
        setModalContent(report)
        setModalReadOnly(true)
    }


    const startEditReport = (report) => {
        const _report = {...report}
        setModal(true)
        setModalContent(_report)
        setModalReadOnly(false)
    }


    const onPageChange = (id) => {
        setPageId(id)
        if(typeof tableNode !== "undefined" && tableNode !== null)
            tableNode.scrollTo(0,0)
    }


    const onFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase())
        onPageChange(0)
    }


    const getUserTotalTS = () => {
        let totalUserMPD = 0
        if(filtered_reports !== null) filtered_reports.forEach((report) => {
            if (report.employee.id === loggedUserData.api_id
                && moment(report.creationDate).utc().isSame(moment().utc(), 'day'))
                totalUserMPD += report.minutesPerDay
        })

        const hours = (totalUserMPD / 60);
        const rhours = Math.floor(hours);
        const rminutes = Math.round((hours - rhours) * 60);

        if(rminutes === 0) return rhours + "h"
        else return rhours + "h " + rminutes + "min";
    }


    return (
        <div className={"page"}>
            <div className={"list-about"}>
                <input type={"text"} value={filter} placeholder={"Refine your results with any term ..."}
                       onChange={(e) => onFilterChange(e)}/>

                {(loggedUserData.roles.includes("ROLE_ADMIN"))? null :
                    <div className={"user-display"}>
                        <p onClick={() => onPageChange(0)} onMouseEnter={() => setTSselected(true)} onMouseLeave={() => setTSselected(false)}>
                            Your time spent for today = <span>{getUserTotalTS()}</span>
                        </p>

                        <Link to={"/add-report"}>
                           <button className={"add-report-trigger"}>
                               <FontAwesomeIcon icon={faPlus} className={"icon"}/>
                               <span>ADD A NEW REPORT</span>
                           </button>
                        </Link>
                    </div>
                }
            </div>

            {(sliced_reports !== null) ?
                <div className={"report-list"}>
                    <Table responsive striped variant="light" className={"report-table"}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>USER</th>
                                <th>PROJECT</th>
                                <th>DEVIS</th>
                                <th>TIME SPENT</th>
                                <th>CREATION DATE</th>
                                <th>STATUS</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {sliced_reports.map((report, index) =>
                                (report.employee.id === loggedUserData.api_id)?
                                    <UserRow report={report} index={index} key={index}
                                             TSselected={isTSselected && report.employee.id === loggedUserData.api_id && moment(report.creationDate).utc().isSame(moment().utc(), 'day')}
                                             displayReport={(report) => displayReport(report)}
                                             startEditReport={(report) => startEditReport(report)}/>
                                             :
                                    <ManagerRow report={report} index={index} key={index}
                                             displayReport={(report) => displayReport(report)}
                                             startEditReport={(report) => startEditReport(report)}/>
                            )}
                        </tbody>
                    </Table>

                    <div className={"pagination"}>
                        <div className={"report-counter"}>
                            {(filtered_reports.length < reports.length)?
                                <p>Number of reports = <span>{filtered_reports.length}</span> / {reports.length}</p>
                                :
                                <p>Number of reports = <span>{filtered_reports.length}</span></p>
                            }
                        </div>

                        {pageCount > 1 &&
                            <ReactPaginate pageCount={pageCount} pageRangeDisplayed={3} marginPagesDisplayed={2}
                                           initialPage={pageId}
                                           forcePage={pageId}
                                           onPageChange={(page) => onPageChange(page.selected)}
                                           previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                                           nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                                           containerClassName={"paginator"}/>
                        }
                    </div>
                </div>

                :

                <Loading message={"Loading reports"}/>
            }

            <ReportModal show={showModal} readOnly={modalReadOnly} handleDelete={(report) => handleDeleteFromModal(report)}
                     report={modalContent} handleClose={(report) => handleCloseEditModal(report)}/>
        </div>
    )
}


export default ReportList
