import axios from "axios";
import {url} from "../conf/env";
import Cookies from "js-cookie";


export function query_api_get(link) {
    if (link == '/api/projects' || link == '/api/devis' || link == '/api/teams'){
        return new Promise((fetch) => {
            axios.get(url.api + link + "?enabled=true&auth_token=" + Cookies.get("sso-reporting-token"), {headers:{"accept": "application/ld+json"}})
                .then((response) => fetch(response.data))
                .catch((error) => {
                    throw_error(error);
                    fetch(null);
                })
        });
    }

    return new Promise((fetch) => {
        axios.get(url.api + link + "?auth_token=" + Cookies.get("sso-reporting-token"), {headers:{"accept": "application/ld+json"}})
            .then((response) => fetch(response.data))
            .catch((error) => {
                throw_error(error);
                fetch(null);
            })
    });
}


export function query_api_post(link, data, headers) {
    data.auth_token = Cookies.get("sso-reporting-token");
    return axios.post(url.api + link,
        data,
        {headers:headers});
}


export function query_api_put(link, data, headers) {
    data.auth_token = Cookies.get("sso-reporting-token");
    return axios.put(url.api + link,
        data,
        {headers:headers});
}


export function query_api_delete(link) {
    return axios.delete(url.api + link + "?auth_token=" + Cookies.get("sso-reporting-token"))
}


export function query_external_get(link, headers) {
    return new Promise((fetch) => {
        axios.get(link, {headers:headers})
            .then((response) => fetch(response.data))
            .catch((error) => {
                throw_error(error);
                fetch(null);
            })
    });
}


function throw_error(err){
    console.error(err);
}
