import {isDate} from "moment";

export function validateINT(int) {
    return int !== null && int !== "" && !isNaN(int);
}

export function validateTIME(time) {
    let timeSplit = time.split(":");
    return (timeSplit.length === 2 && !isNaN(timeSplit[0]) && !isNaN(timeSplit[1]));
}

export function validateDATE(date) {
    return date !== null && date !== "" && isDate(date);
}

export function validateOBJECT(object, objectList) {
    return object !== null
        && typeof object.metadata !== "undefined"
        && objectList.metadata.find((obj) => obj.id = object.metadata.id);
}