import Table from "react-bootstrap/Table";
import React from "react";
import AddReportModal from "../../components/Modal/AddReportModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import ReactTooltip from "react-tooltip";



export default class Overview extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            modalContent: null
        }
    }


    handleCloseModal(){
        this.setState({showModal: false});
    }


    displayModal(report){
        this.setState({
            showModal: true,
            modalContent: report
        });
    }


    render(){

        return(
            <div className={"overview content"}>
                <div className={"container"}>
                    <Table className={"overview-table"} borderless striped responsive>
                        <thead>
                        <tr>
                            <th>PROJECT</th>
                            <th>REPORT</th>
                            <th>TIME SPENT</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.reportsList.map((report, index) =>
                                <tr key={index}>
                                    <td className={"project"} data-tip={"Devis: " + report.devis.displayData} data-for={"display-devis-data"}>{report.project.displayData}</td>
                                    <td className={"report-content"} onClick={() => this.displayModal(report)}>{report.content.substring(0, 10) + " ..."}</td>
                                    <td>{report.timeSpent.replace(':', 'h')}</td>

                                    <td className={"delete-report"}
                                          onClick={() => this.props.removeReport(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>

                    <ReactTooltip id={"display-devis-data"} type={"dark"} place="bottom" effect="solid"/>

                    <AddReportModal show={this.state.showModal} report={this.state.modalContent} handleClose={() => this.handleCloseModal()}/>

                </div>

                <div className={"control-container"}>
                    <button onClick={()=>this.props.switchContainer("form")}>ADD MORE</button>
                    <button className={"important"} onClick={()=>this.props.switchContainer("post")}>POST</button>
                </div>
            </div>
        );
    }
};