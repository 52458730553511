import React from "react";
import "./style.scss";
import Overview from "./Overview";
import ReportForm from "./ReportForm";
import {animErrorPost} from "../../helpers/animation";
import moment from "moment";
import { Redirect } from "react-router-dom"
import {query_api_post} from "../../helpers/ajax";
import {clockToMinutes} from "../../helpers/format-helper";
import Cookies from "js-cookie";
import {Base64} from "js-base64"
import loader from "../../res/icons/loader-black.svg";
import {updateReportingRawData} from "../../providers/provider";



export default class AddReport extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            classContainerDisplayed: "form",
            reportsList: [],
            keepFormState: true,
            redirect: false,
            isPosting: false,
            userID: JSON.parse(Base64.decode(Cookies.get("sso-reporting-data"))).api_id
        };
        this.toggleContainerSwitch = this.toggleContainerSwitch.bind(this);
    }


    toggleContainerSwitch(container_name){
        this.setState({classContainerDisplayed: container_name});
        if(container_name === "form") this.setState({keepFormState: true});

        this.moveMask(container_name);
    }


    moveMask(_dest) {
        let mask = document.getElementById("state-mask");
        let container = document.getElementById("state-container");

        if(_dest === "form")
            mask.style.left = "-50px";
        else if(_dest === "overview")
            mask.style.left = container.offsetWidth / 2 - mask.offsetWidth / 2 + "px";
        else if(_dest === "post")
            mask.style.left = container.offsetWidth - mask.offsetWidth + 50 + "px";
    }


    addReport(report){
        this.state.reportsList.push(report);
        this.setState({
            keepFormState: false,
        });
        this.toggleContainerSwitch("overview");
    }


    removeReport(report_index){
        let reportsList = [...this.state.reportsList];
        reportsList.splice(report_index, 1);
        this.setState({reportsList});
    }


    postReports(){

        let post_promises = [];
        this.setState({isPosting: true})

        this.state.reportsList.forEach((new_report) => {

            post_promises.push(query_api_post("/api/reports", {
                content: new_report.content,
                minutesPerDay: clockToMinutes(new_report.timeSpent, ':'),
                employee: "/api/employees/" + this.state.userID,
                devis: new_report.devis.metadata['@id'],
                isSent: false,
                creationDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                ticketNo: new_report.ticketNo
            }));
        });

        Promise.all(post_promises)
            .then((responses) => {
                const new_reports = []
                responses.forEach((res) => new_reports.push(res.data))
                updateReportingRawData(new_reports, "reports")
                this.setState({redirect: true, isPosting: false})
            })
            .catch((error) => {
                console.error(error);
                animErrorPost();
                this.setState({isPosting: false})
            });
    }


    render() {

        let message = "Please fill in the form.";
        if(this.state.classContainerDisplayed === "overview") message = "You can create more reports in the first section.";
        else if(this.state.classContainerDisplayed === "post") message = "Are you sure ?";


        if(this.state.redirect) return <Redirect to='/reports'/>;

        else return(
            <div className={"page"} style={{overflowY: "scroll"}}>
                <div id={"add-report-container"}>

                    <h2>ADD REPORT</h2>
                    <p id={"advice"}>{message}</p>

                    <div id={"state-band"}>
                        <div id={"state-container"}>
                            <span id="state-mask">{this.state.classContainerDisplayed.toUpperCase()}</span>

                            <span className={(this.state.classContainerDisplayed === "form")? "active" : null}
                                  onClick={()=>this.toggleContainerSwitch("form")}>FORM</span>

                            <span className={(this.state.classContainerDisplayed === "overview")? "active" : null}
                                  onClick={()=>this.toggleContainerSwitch("overview")}>OVERVIEW</span>

                            <span className={(this.state.classContainerDisplayed === "post")? "active" : null}
                                  onClick={()=>this.toggleContainerSwitch("post")}>POST</span>
                        </div>
                    </div>

                    {this.state.keepFormState &&
                        <ReportForm display={(this.state.classContainerDisplayed === "form")}
                                    addReport={(report) => this.addReport(report)}/>
                    }

                    {this.state.classContainerDisplayed === "overview" &&
                        <Overview reportsList={this.state.reportsList} removeReport={(index) => this.removeReport(index)}
                                  switchContainer={(container) => this.toggleContainerSwitch(container)}/>
                    }

                    {this.state.classContainerDisplayed === "post" &&
                        <div className={"post content"}>
                            <div className={"container"}>
                                <p>You are about to create {this.state.reportsList.length} reports. Are you sure ?</p>
                            </div>

                            {(!this.state.isPosting) ?
                                <div className={"control-container"}>
                                    <button onClick={()=>{this.postReports()}}>YES</button>
                                    <button className={"important"} onClick={()=>this.toggleContainerSwitch("overview")}>CANCEL</button>
                                </div>
                                :
                                <img src={loader} alt={"loader"}/>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}